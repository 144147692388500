<template>
    <div class="feedback-list-wrapper" v-loading="loadingFeedbackList">
        <ul
            v-if="!feedbackListError"
            class="feedback-list"
            v-infinite-scroll="load"
            style="overflow:auto">
            <li
                v-for="(item, index) in feedbackList"
                :key="index"
                class="feedback-list-item"
                @click="goToDetail(item)"
            >
                <h3 class="title" :title="item.feedDesc">
                    <span v-if="item.feedAcceptanceRecordList.length > 0" class="markings">已回复</span>{{ item.feedDesc || '暂无描述' }}
                    <span 
                        v-if="item.feedStatus == '2' && item.isRead == '0'"
                        class="reminders"
                    >
                    </span>
                </h3>
                <p class="time">{{ item.createTime }}</p>
                <span class="to-detail-group">
                    <span class="detail-title">查看详情</span>
                    <span>
                        <img class="to-detail" :src="detailImg" alt="detail">
                    </span>
                </span>
            </li>
            <li class="no-list" v-if="feedbackList.length === 0">
                暂无反馈记录～
            </li>
        </ul>
        <div
            v-if="feedbackListError"
            class="feedback-list-error">
                <el-button type="primary" @click="retry">点击重试</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "FeedbackList",
    props: {
        feedbackList: Array,
        loadingFeedbackList: Boolean,
        feedbackListError: Boolean
    },
    data () {
        return {
            count: 10
        }
    },
    computed: {
        detailImg () {
            return require('./img/to-detail.png')
        }
    },
    methods: {
        goToDetail (info) {
            this.$emit('goToDetail', info)
        },
        load () {
            console.log('load')
        },
        retry () {
            this.$emit('retry')
        }
    }
}
</script>
<style lang="scss" scoped>
    .feedback-list {
        height: calc(100vh - 116px);
        box-sizing: border-box;
        padding: 0 20px;
        margin: 0px;
        .feedback-list-item {
            height: 96px;
            border-bottom: 1px solid #ECECEC;
            cursor: pointer;
            .title {
                width: 400px;
                display: flex;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                white-space: nowrap;
                padding-top: 14px;
                .markings {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    background: #3D7FFF;
                    border-radius: 2px;
                    padding: 3px 4px;
                    margin-right: 6px;
                }
                .reminders {
                    display: block;
                    width: 7px;
                    height: 7px;
                    background: #F84141;
                    border-radius: 3px;
                }
            }
            .time {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                margin-top: 2px;
                color: #999999;
            }
            .to-detail-group {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 8px;
                cursor: pointer;
                .detail-title {
                    margin-top: 1px;
                    display: block;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
                .to-detail {
                    display: block;
                    width: 6px;
                    height: 10px;
                    margin-left: 5px;
                }
            }
        }
        .no-list {
            margin-top: 120px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 49px;
        }
    }
    .feedback-list-error {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 120px;
    }
</style>
