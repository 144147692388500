<template>
    <div class="feedback-detail" v-infinite-scroll="load" style="overflow:auto">
        <p class="time">{{ detailData.createTime }}</p>
        <detail-group
            title="反馈对象"
            :detailText="detailData.resourceId"
        >
        </detail-group>
        <evaluation-details
            first
            title="功能完善:"
            :star="detailData.experienceRating.completeFunctionality"
        >
        </evaluation-details>
        <evaluation-details
            title="运行质量:"
            :star="detailData.experienceRating.qualityOperation"
        >
        </evaluation-details>
        <evaluation-details
            title="操作简单:"
            :star="detailData.experienceRating.simpleOperation"
        >
        </evaluation-details>
        <evaluation-details
            title="界面美观:"
            :star="detailData.experienceRating.beautifulInterface"
        >
        </evaluation-details>
        <evaluation-details
            title="服务质量:"
            :star="detailData.experienceRating.serviceQuality"
        >
        </evaluation-details>
        <detail-group
            v-if="detailData.feedDesc"
            title="问题描述"
            mt
            :detailText="detailData.feedDesc"
        >
        </detail-group>
        <div class="picture-list" v-if="srcList.length > 0">
            <el-image
                v-for="(item, index) of srcList"
                :key="index"
                :src="item"
                :preview-src-list="srcList"
                style="width: 100px; height: 100px"
                class="img"
            >
            </el-image>
        </div>
        <detail-group
            v-if="detailData.phone"
            title="联系方式"
            mt
            :detailText="detailData.phone"
        >
        </detail-group>
        <detail-group
            title="反馈回复"
            mt
        >
            <div class="restore-list" v-if="detailData.feedAcceptanceRecordList.length > 0">
                <div
                    v-for="(item, index) of detailData.feedAcceptanceRecordList"
                    :key="index"
                    :class="['restore-list-item', {current: index < detailData.feedAcceptanceRecordList.length - 1}]"
                >
                    <p class="title">
                        <span class="name">小鑫客服</span>
                        <span class="time">{{ item.createTime }}</span>
                    </p>
                    <p class="text">{{ item.remark }}</p>
                </div>
            </div>
            <div
                v-if="detailData.feedAcceptanceRecordList.length === 0"
                class="no-reply"
            >
                暂无回复
            </div>
        </detail-group>
    </div>
</template>
<script>
import EvaluationDetails from './components/EvaluationDetails.vue';
import DetailGroup from './components/DetailGroup.vue';
import { updateReadStatus } from './https/index.js';
export default {
    name: "FeedbackDetail",
    components: {
        EvaluationDetails,
        DetailGroup
    },
    props: {
        detailInfo: Object
    },
    data () {
        return {
            agencyApi: process.env.VUE_APP_XKSERVER_FEED_AGENCY_API, // 接口请求地址
            detailData: {
                createTime: '',
                resourceId: '',
                feedDesc: '',
                phone: '',
                feedAcceptanceRecordList: [],
                experienceRating: {},
                feedPicture: ''
            },
            srcList: []
        }
    },
    created () {
        this.init()
    },
    methods: {
        init () {
            this.initDetailData();
            this.getHasProcessed();
        },
        initDetailData () {
            Object.keys(this.detailInfo).forEach(key => {
                Object.keys(this.detailData).forEach(subKey => {
                    if (key === subKey) {
                        this.detailData[subKey] = this.detailInfo[key];
                    }

                    if (this.detailInfo.experienceRating) {
                        this.detailData.experienceRating = JSON.parse(this.detailInfo.experienceRating);
                    }
                })
            })

            Object.keys(this.detailData.experienceRating).forEach(key => {
                this.detailData.experienceRating[key] = Number(this.detailData.experienceRating[key]);
            })

            if (this.detailData.feedPicture) {
                let eachImg = this.detailData.feedPicture.split(',');
				eachImg.forEach(i => {
					if (i.includes('http')) {
                        // 如果是小程序那边上传的图片，不拼接前缀
						this.srcList = eachImg.filter(iv => iv !== '')
					} else {
						this.srcList = eachImg.filter(iv => iv !== '').map(iv => this.imgUrl(iv))
					}
				})
            };

        },
        /**
         * @Description: 说明
         * @Author: 作者
         * @Date: 2024-09-06 11:21:30
         */        
        getHasProcessed() {
            let processed = this.detailData.feedAcceptanceRecordList.filter(item => item.feedStatus === '2');
            if (processed.length > 0) {
                let id = processed[0].feedId;
                console.log(processed, id, 'processed')
                updateReadStatus({'id': id}, this.agencyApi)
                .then((res) => {
                    console.log(res,'res-已读',this.$store)
                    if (res.code === '200') {
                        this.$store.dispatch('getHasNewFeedback')
                    }
                })
                .catch((err) => {
                    console.log(err,'res-')
                })
            }
            console.log(this.detailData.feedAcceptanceRecordList,'detailData.feedAcceptanceRecordList')
        },
        load () {
            console.log('load')
        },
        imgUrl(url) {
            return process.env.VUE_APP_ASSETS + url
        }
    }
}
</script>
<style lang="scss" scoped>
    .feedback-detail {
        height: calc(100vh - 116px);
        box-sizing: border-box;
        padding: 0 20px;
        .time {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 21px;
        }


        .picture-list {
            margin-top: 20px;
            .img {
                width: 70px !important;
                height: 70px !important;
                margin-right: 10px;
                overflow: hidden;
                border-radius: 6px;
            }
        }
        .restore-list {
            background: #F6F6F6;
            border-radius: 6px;
            box-sizing: border-box;
            padding: 0 12px 12px;
            .restore-list-item {
                padding-top: 10px;
                .title {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    color: #333333;

                    .name {
                        font-weight: 500;
                        color: #333333;
                    }
                    .time {
                        font-weight: 500;
                        color: #333333;
                        margin-left: 20px;
                    }
                }
                .text {
                    word-break: break-word;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                    box-sizing: border-box;
                    padding: 4px 0px 11px;
                }
                &.current {
                    border-bottom: 1px dashed #DFDFDF;
                }
            }
        }
        .no-reply {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            box-sizing: border-box;
            padding: 8px !important;
            background: #F6F6F6;
            border-radius: 6px;
        }
    }
</style>
