<template>
    <div class="feedback-form" v-infinite-scroll="load">
        <select-module
            :pageIds="pageIds"
            :options="moduleOptions"
            @changeSelectModule="changeSelectModule"
        >
        </select-module>
        <star title="功能完整" @starStatus="starStatus(arguments, 'completeFunctionality')"></star>
        <star title="运行质量" @starStatus="starStatus(arguments,'qualityOperation')"></star>
        <star title="操作简单" @starStatus="starStatus(arguments,'simpleOperation')"></star>
        <star title="界面美观" @starStatus="starStatus(arguments,'beautifulInterface')"></star>
        <star title="服务质量" @starStatus="starStatus(arguments,'serviceQuality')"></star>

        <div v-if="showMoreForm" class="domain-wrapper">
            <textarea-group
                :agencyApi="agencyApi"
                @changeTextarea="changeTextarea"
                @notUploadedImg="notUploadedImg"
                @setFeedPicture="setFeedPicture"
                @drawerChange="drawerChange"
            >
            </textarea-group>
        </div>
        <contacts
            @changeContacts="changeContacts"
        >
        </contacts>
        <btn-group
            :disabled="disabled"
            :remainingImgNum="remainingImgNum"
            @submit="submit"
            @close="close"
            >
        </btn-group>
    </div>
</template>
<script>
import SelectModule from './components/SelectModuleOption.vue';
import Star from './components/StarGroup.vue';
import Contacts from './components/ContactGroup.vue';
import BtnGroup from './components/BtnGroup.vue';
import TextareaGroup from './components/TextareaGroup.vue';

export default {
    name: "FeedbackForm",
    components: {
        SelectModule,
        Star,
        Contacts,
        BtnGroup,
        TextareaGroup
    },
    props: {
        userRouters: Array,
        submitFormData: Object
    },
    data () {
        return {
            agencyApi: process.env.VUE_APP_XKSERVER_FEED_AGENCY_API, // 接口请求地址
            pageName: '',
            pageIds: [],
            moduleOptions: [],
            showMoreForm: false,

            disabled: false,
            remainingImgNum: 0,
        }
    },
    created () {
        this.init ()
    },
    methods: {
        init () {
            this.initPageData();
        },
        initPageData () {
            const pagePath = this.getRouterPath();
            this.moduleOptions = this.getFormattingRouter(this.userRouters, pagePath);
            if (this.pageName) {
                this.findParent(this.userRouters, this.pageName, this.pageIds);
                this.changeSelectModule(this.pageIds)
            }
        },
        getRouterPath () {
            return window.location.hash.split('#')[1];
        },
        getFormattingRouter (userRouters, pagePath) {
            let _this = this;
            userRouters.map(item => {
                item['label'] = item.title;
                item['value'] = item.menuId;

                if (item.path && item.path === pagePath) {
                    let moduleName = item.label || item.title;
                    _this.pageName = item.menuId;
                }

                if (item.children && item.children.length > 0) {
                    this.getFormattingRouter (item.children, pagePath)
                }
            })

            return userRouters;
        },
        //data：要遍历的数据， target：查找目标， result用于装查找结果的数组
        findParent(data, target, result) {
            for (let i in data) {
                let item = data[i]
                if (item.menuId === target) {
                    //将查找到的目标数据加入结果数组中
                    //可根据需求unshift(item.id)或unshift(item)
                    result.unshift(item.menuId)
                    return true
                }
                if (item.children && item.children.length > 0) {
                    let ok = this.findParent(item.children, target, result)
                    if (ok) {
                        result.unshift(item.menuId)
                        return true
                    }
                }
            }
            //走到这说明没找到目标
            return false
        },
        changeSelectModule (value) {
            this.$emit('changeSelectModule', value)
        },
        starStatus () {
            const STAR_STABDARD = 2;
            const [ args ,tar] = this.formattingStar(arguments);
            const starNum = this.formattingStar(args)[0];
            let starList = [];

            this.$emit('starStatus',[tar, starNum])
            Object.keys(this.submitFormData.experienceRating).forEach(item => {
                let starItem = Number(this.submitFormData.experienceRating[item]);
                starList.push(starItem);
            });

            this.showMoreForm = starList.some(item => {
                return item >= STAR_STABDARD;
            });
        },
        formattingStar (args) {
            return Array.prototype.slice.call(args);
        },
        /**
         * 文本域相关
         *
         * */
        changeTextarea (value) {
            this.$emit('changeTextarea', value)
        },
        /**
         * @Description: 未上传图片
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-04 15:07:15
         */
        notUploadedImg (num) {
            this.remainingImgNum = num;
        },
        setFeedPicture (arr) {
            this.$emit('setFeedPicture', arr)
        },
        drawerChange () {
            this.$emit('drawerChange')
        },
        changeContacts (value) {
            this.$emit('changeContacts', value)
        },
        submit () {
            this.$emit('submit')
        },
        close () {
            this.$emit('close')
        },
        load () {

        }
    }
}
</script>
<style lang="scss" scoped>
    .feedback-form {
        height: calc(100vh - 90px);
        overflow:auto;
    }
    .domain-wrapper {
        width: 100%;
        position: relative;
    }
</style>
