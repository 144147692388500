import Vue from 'vue';
import store from '../../../store'
import router from '@/router';
export default function createFeedback (Component, props) {
    const vm = new Vue({
        store,
        router,
        render(h) {
            return h(Component, {props})
        }
    }).$mount()

    document.body.appendChild(vm.$el);
    const comp = vm.$children[0];

    comp.close = () => {
        vm.$destroy()
        document.getElementById('J_feedback') && vm.$el && document.body.removeChild(vm.$el)
    };

    return comp;
}
